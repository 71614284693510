export const ClicksignRequirements = [
  {
    label: "Contratada",
    value: "agree:contractee",
  },
  {
    label: "Contratante",
    value: "agree:contractor",
  },
  {
    label: "Empregado",
    value: "agree:employee",
  },
  {
    label: "Empregador",
    value: "agree:employer",
  },
  {
    label: "Representante Legal",
    value: "agree:legal_representative",
  },
  {
    label: "Sócio",
    value: "agree:partner",
  },
  {
    label: "Testemunha",
    value: "agree:witness",
  },
  {
    label: "Aprovador",
    value: "agree:approve",
  },
];
